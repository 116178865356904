const CountryNotice = [
    {
        countryRegionId: 'VNM',
        lang: 'en-us',
        value: `<p>The products for sale on this website are not intended to be sold to the general public, but are intended for sale and use exclusively by members of The Church of Jesus Christ of Latter-day Saints (the “Church”) and others who are interested in the doctrine, beliefs, and practices of the Church. For ordering instructions please see <u><a href="https://store.churchofjesuschrist.org/store-help">FAQ.</a></u></p></br></br><p>Các sản phẩm được bán trên trang mạng này không nhằm để bán cho công chúng, mà nhằm để bán và dành riêng cho các tín hữu của Giáo Hội Các Thánh Hữu Ngày Sau của Chúa Giê Su Ky Tô (“Giáo Hội”) và những người khác mà đang quan tâm đến giáo lý, tín ngưỡng và lối thực hành của của Giáo Hội. Để được hướng dẫn đặt hàng, xin vào xem phần Các Câu Hỏi Thường Gặp <u><a href=""https://store.churchofjesuschrist.org/store-help"">FAQ.</a></u></p></br>`
    },
    {
        countryRegionId: 'VEN',
        lang: 'en-us',
        value: `This website is for reference only and will not allow purchases to be completed. Please visit the Caracas retail store to purchase church materials.
        <br>
        <br>
        Avenida C con Calle C-1<br>
        Urbanization Caurimare<br>
        Caracas, Miranda 1061<br>
        +58 0412 300 7860<br>
        +58 0424166 6042<br>
        pedidosvenezuela@churchofjesuschrist.org`
    },
    {
        countryRegionId: 'VEN',
        lang: 'es',
        value: `Este sitio es solo informativo, por lo que no le permitirá completar ninguna compra en línea. Para obtener materiales de La Iglesia, comuníquese con el Centro de Distribución de Caracas:
        <br>
        <br>
        Avenida C con Calle C-1<br>
        Urbanization Caurimare<br>
        Caracas, Miranda 1061<br>
        +58 0412 300 7860<br>
        +58 0424166 6042<br>
        pedidosvenezuela@churchofjesuschrist.org`
    }
];

export default CountryNotice;
